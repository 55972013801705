import { Icon as IconTypes } from '@/ts/types/icons';
import iconMap from './helpers/iconMap';

const Icon: React.FC<{ name: IconTypes; color?: string }> = ({ name, color = 'currentColor' }) => {
  const IconComponent = iconMap[name.toLowerCase() as IconTypes];
  if (!IconComponent) return null;
  return <IconComponent id={`icon-${name.toLowerCase() as IconTypes}`} color={color} />;
};

export default Icon;
